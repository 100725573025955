/* App.css */

/* Ensure the body and html elements take up the full height */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  font-family: 'Courier New', monospace;
}

/* Style for the content section */
.content-section {
  margin-top: 7%;
  background-color: white; /* White background for the content section */
  min-height: 10vh; /* Minimum height to fill the viewport and enable scrolling */
  padding: 20px; /* Add some padding */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}
